a:link, a:visited {
  color: #585858;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 0.85em;
}

a:hover, a:active {
  color: #F2F2F2;
  font-size: 0.90em;
}